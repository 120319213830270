
import Header from '../components/Header';
import Footer from '../components/Footer';
import JoinLevel from '../components/JoinLevel';



const Join = () => {

  return (
    <div>
      <Header />
      <div className='join'>
        <div className='join__content'>
          <h2>Pourquoi adhérer?</h2>
          <p>Pour échanger, s’informer, se former.</p> 
          <p>Echanger lors de rencontres conviviales :</p>
          <ul>
            <li>Réunions de secteur</li>
            <li>Assemblée générale</li>
            <li>Journées de formations </li>
            <li>Sortie d’été culturelle avec les enfants.</li> 
          </ul>
          <p>Etre écouté et accompagné.</p>
          <p>Etre informé des évolutions survenues ou à venir au niveau départemental et national.</p>
          <p>Bénéficier :</p>
          <ul>
            <li>De la bibliothèque</li>
            <li>D’un accès aux sites internet
              <ul>
                <li><a href='https://assfamaccueil53.org' target='blank' alt="site des familles d'accueil de la Mayenne">assfamaccueil53.org</a></li>
                <li><a href='https://fnaf.info' target='blank' alt="site de la fnaf">fnaf.info</a></li>
              </ul>
            </li>
            <li>D'un accès aux conseils juridiques de Maître CACCIAPAGLIA via FNAF/PE</li>
            </ul>
            <p>Pour tout renseignement concernant la <b>Protection juridique professionnelle</b> proposée, contacter <a href ='mailto:paillard7@gmail.com'>KATY PAILLARD</a>.<br/>
            (Tarif dérogatoire et préférentiel 85€)
            </p>
            <h2>Comment adhérer?</h2>
            <p>Pour adhérer, merci de remplir les 2 bulletins ci dessous</p>
            <div className='join-form'>
              <a href='./assets/Fiche_adhesion_asso.pdf' target='blank'>Bulletin d'adhésion asso et FNAF/PE</a><hr/>
              <a href='./assets/Fiche_adhesion_udaf.pdf' target='blank'>Bulletin d'adhésion UDAF</a>
            </div>
        </div> 
        <div className='join__level'>
          <JoinLevel />
        </div> 
        </div>
       
      <Footer />
    </div>
  )
}

export default Join
